<section class="l-siteContent" id="theTop" *ngIf="!registered">
  <div class="row align-center">
    <div class="small-12 medium-10 large-6 xlarge-5 column">
      <h1 class="l-headDecor"><img src="/assets/images/bomo_hand_gray_mono.svg"
                                   alt="Regisztráció">{{ 'webshop.Regisztráció' | transloco }}</h1>
      <form id="entity_form" class="form form--signUp" data-abide novalidate (ngSubmit)="registration()"
            [formGroup]="group">
        <img class="anim anim--fromLeft decorImg decorImg--signUp" src="/assets/images/decor/mosquito.png"
             alt="{{ 'webshop.Regisztráció' | transloco}}">
        <div data-abide-error class="alert callout h-hidden">
          <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
        </div>

        <label>{{ 'webshop.Vezetéknév' | transloco }}
          <input type="text" [formControlName]="'lastName'">
          <span class="form-error"
                *ngIf="group.get('lastName').hasError('required') && group.get('lastName').touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
        </label>

        <label>{{ 'webshop.Keresztnév' | transloco }}
          <input type="text" [formControlName]="'firstName'">
          <span class="form-error"
                *ngIf="group.get('firstName').hasError('required') && group.get('firstName').touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
        </label>
        <label>{{ 'webshop.E-mail' | transloco }}
          <input type="text" [placeholder]="'webshop.E-mail' | transloco" [formControlName]="'email'">
          <span class="form-error"
                *ngIf="group.get('email').hasError('email') && group.get('email').touched">{{ 'webshop.Ez a mező nem felel meg az e-mail formátumnak.' | transloco }}</span>
          <span class="form-error"
                *ngIf="group.get('email').hasError('required') && group.get('email').touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
          <span class="form-error"
                *ngIf="group.get('email').hasError('owned') && group.get('email').touched">{{ 'webshop.Ezt az email cím már foglalt, kérjük jelentkezz be' | transloco }}</span>
        </label>
        <label>{{ 'webshop.Telefon' | transloco }}
          <p style="display: flex; align-items: baseline">
            <span style="display: inline-block; margin-right: 5px">+</span>
            <input [formControlName]="'phone'" type="tel" pattern="[0-9]*"
                   [placeholder]="'webshop.Telefon' | transloco" autocomplete="false">
          </p>
          <span class="form-error"
                *ngIf="group.get('phone').hasError('required') && group.get('phone').touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
        </label>
        <label>{{ 'webshop.Jelszó' | transloco }}
          <input type="password" [placeholder]="'webshop.Jelszó' | transloco" [formControlName]="'password'">
          <span class="form-error"
                *ngIf="group.get('password').hasError('required') && group.get('password').touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
        </label>
        <label>{{ 'webshop.Jelszó megerősítése' | transloco }}
          <input type="password" [placeholder]="'webshop.Jelszó megerősítése' | transloco"
                 [formControlName]="'password2'">
          <span class="form-error"
                *ngIf="group.get('password2').hasError('required') && group.get('password2').touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
          <span class="form-error"
                *ngIf="group.get('password2').hasError('passwordsNotEqual') && group.get('password2').touched">{{ 'webshop.A jelszavak nem egyeznek' | transloco }}</span>
        </label>
        <div class="row">
          <div class="small-12 medium-6 column">
            <div class="g-top-thin">
              <input id="needNewsletter" type="checkbox"><label
              for="needNewsletter">{{ 'webshop.Hírlevelet kérek' | transloco }}</label>
            </div>
          </div>
          <div class="small-12 medium-6 column">
            <div class="text-right">
              <button class="btn btn--basic" type="submit">{{ 'webshop.Fiók létrehozása' | transloco }}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="l-siteContent" id="theTop" *ngIf="registered">
  <section class="feedbackMessage">
    <div class="row align-center">
      <div class="small-12 medium-10 large-6 xlarge-4 column">
        <h1>{{ 'webshop.Köszönjük, hogy regisztráltál.' | transloco }}</h1>
        <img src="/assets/images/menu_heading_decor.svg" alt="decor">
        <div class="feedbackMessage-content g-top">
          <p>{{ 'webshop.Nemsokára e-mail-t kapsz tőlünk. A levélben található linkre kattintva tudod megerősíteni regisztrációdat.' | transloco }}</p>
          <p>{{ 'webshop.Ez után ne feledd el befejezni a regisztrációs folyamatot és kitölteni a vásárláshoz szükséges egyéb adatid (számlázási cím, szállítási cím, stb)' | transloco }}</p>
          <div class="g-top">
            <p>{{ 'webshop.Köszönjük:' | transloco }}</p>
            <span class="feedbackMessage-signature">{{ 'webshop.Bomo Art csapata' | transloco }}</span>
          </div>
          <img class="anim anim--fromLeft decorImg decorImg--signUpAfterLeft" src="/assets/images/decor/balloon_01.png"
               alt="decor">
          <img class="anim anim--fromRight decorImg decorImg--signUpAfterRight"
               src="/assets/images/decor/balloon_02.png" alt="decor">
        </div>
      </div>
    </div>
  </section>
</section>
