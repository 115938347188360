<div class="mat-elevation-z8">
  <mat-toolbar>
    <mat-toolbar-row>
      {{ getService().getEntityTitle() | async }} {{ 'form.listing' | transloco }}
    </mat-toolbar-row>
    <mat-toolbar-row>
      <!--button style="margin-right: 15px" mat-raised-button color="primary"
              routerLink="./create">{{ 'form.new' | transloco }}</button-->
      <button mat-raised-button color="warn" (click)="batchDelete()">{{ 'form.batchDelete' | transloco }}</button>
    </mat-toolbar-row>
  </mat-toolbar>
  <table mat-table class="full-width-table" matSort>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" [attr.data-label]="''">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container *ngFor="let column of displayedColumns" matColumnDef="{{ column }}">
      <ng-container [ngSwitch]="column">
        <ng-container *ngSwitchCase="'shippingMode'">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>Szállítási mód
          </th>
          <td mat-cell *matCellDef="let row" [attr.data-label]="'Szállítási mód?'">
            {{ row[column] }} {{ row['store'].id ? (' (' + row['store'] + ' )') : '' }}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'manufacturable'">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>Gyártandó
          </th>
          <td mat-cell *matCellDef="let row" [attr.data-label]="'Gyártandó'">
            <mat-icon *ngIf="row.manufacturable === '1'">check</mat-icon>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'active'">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>{{ 'active' | transloco }}
          </th>
          <td mat-cell *matCellDef="let row" [attr.data-label]="'active'">
            <mat-icon *ngIf="row.active === 1">check</mat-icon>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'log'">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>Megjegyzés
          </th>
          <td mat-cell *matCellDef="let row" [attr.data-label]="'Megjegyzés'">
            <ng-container *ngIf="row[column]">{{ row[column] }}</ng-container>
            <ng-container *ngIf="row.innerDescription">{{ row.innerDescription }}</ng-container>
          </td>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>{{ column !== 'id' ? (translationDomain + '.' + column | transloco) : ('id' | transloco) }}
          </th>
          <td mat-cell *matCellDef="let row"
              [attr.data-label]="column !== 'id' ? (translationDomain + '.' + column | transloco) : ('id' | transloco)">
            <ng-container *ngIf="fieldConfig(column)?.type === 'dateRange'">
              {{ row[column] | dateTime }}
            </ng-container>
            <ng-container *ngIf="fieldConfig(column)?.type === 'select'">
              {{ fieldConfig(column)?.getOptionName(row[column]) | async }}
            </ng-container>
            <ng-container *ngIf="fieldConfig(column)?.type !== 'dateRange' && fieldConfig(column)?.type !== 'select'">
              <ng-container *ngIf="column === 'sumDiscountedPrice'">
                {{ (row[column] + row['shippingPrice']) | adminCurrency: (row['currency'] === 'HUF' ? 'Ft' : 'EUR') :  row['currency'] === 'HUF' ? '1.0-2' : '1.0-2' : row['currency'] === 'HUF' ? 'hu' : 'en' }}
              </ng-container>
              <ng-container *ngIf="column !== 'sumDiscountedPrice'">
                {{ row[column] }}
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="filter_select">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>

    <ng-container *ngFor="let column of displayedColumns" matColumnDef="filter_{{ column }}">
      <ng-container [ngSwitch]="column">
        <ng-container *ngSwitchCase="'user'"> <th mat-header-cell *matHeaderCellDef>
          <p dynamicField
             [field]="fieldConfig(column)" [group]="filterForm"></p>
        </th></ng-container>
        <ng-container *ngSwitchCase="'manufacturable'"><th mat-header-cell *matHeaderCellDef></th></ng-container>
        <ng-container *ngSwitchCase="'log'"><th mat-header-cell *matHeaderCellDef></th></ng-container>
        <ng-container *ngSwitchDefault>
          <th mat-header-cell *matHeaderCellDef>
            <p *ngIf="fieldConfig(column)?.visible && fieldConfig(column)?.filterable" dynamicField
               [field]="fieldConfig(column)" [group]="filterForm"></p>
          </th>
        </ng-container>
      </ng-container>
    </ng-container>


    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | transloco }}</th>
      <td mat-cell *matCellDef="let row">
        <a mat-mini-fab color="primary" [routerLink]="['./', row.id]">
          <mat-icon>edit</mat-icon>
        </a>
        <button mat-mini-fab color="warn" (click)="delete(row.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="filter_actions">
      <th mat-header-cell *matHeaderCellDef>
        <button (click)="resetFilter()" mat-raised-button color="primary">{{ 'reset' | transloco }}</button>
      </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="getFilterColumns()"></tr>
    <tr mat-header-row *matHeaderRowDef="getAllColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getAllColumns();"
        [ngClass]="{ 'active': row.active === 1, 'inactive': row.active === 0}"
        (click)="selection.toggle(row)">
    </tr>
  </table>

  <mat-paginator
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
